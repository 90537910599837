@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap");

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Montserrat", sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

html {
    box-sizing: border-box;
    font-size: 16px;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

ol,
ul {
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}

body {
    position: relative;
    min-height: 100vh;
    margin-right: 16px; /* Reserve space for the scrollbar */
    overflow-y: scroll; /* Force the scrollbar to always be visible */
}

@media (max-width: 768px) {
    body {
        margin-right: 0;
    }
}

.page-wrapper {
    width: 960px;
    min-height: 100vh;
    background-color: #e9e6e6;
}

.main-footer {
    max-width: 960px;
    margin: 0 auto;
}

.page-container {
    padding-bottom: 122px; /* set padding to footer height */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 122px; /* set footer height */
}

.video-container {
    max-width: fit-content;
    max-height: fit-content;
    margin: 0 auto;
    padding: 20px;
}

@media (min-height: calc(100vh + 122px)) {
    footer {
        position: static;
    }
}

@media only screen and (max-width: 960px) {
    .page-wrapper {
        max-width: 100%;
    }
    .main-footer {
        max-width: 100%;
    }
}
